@use "@scss/global.scss" as *;

.text-editor-menu-utils-bar {
  padding: 10px;
  border-bottom: 1px solid $gray-1;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  .menu-utils-group {
    border: 1px solid $gray-1;
    border-radius: 8px;
    width: max-content;
    display: flex;
    flex-wrap: nowrap;

    .menu-utils-button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 26px;
      border: none;

      &:hover {
        background-color: $gray-2;
      }

      &.is-active {
        background-color: $gray-1;
      }

      &:disabled {
        pointer-events: none;
        cursor: default;
        color: $black-4;
      }
    }

    .menu-utils-button:first-child {
      border-radius: 8px 0 0 8px;
    }

    .menu-utils-button:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
}
