@use "@scss/global.scss" as *;

.panel-layout {
  .header-part {
    width: 100%;
    height: max-content;
  }

  .navbar-part {
    height: max-content;
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 10;
  }

  .content-part {
    width: 100%;
    min-height: calc(100vh - 390px);
    height: 100%;
    max-width: $xl;
    padding: 0px 15px;
    margin: 0 auto;
  }

  .footer-part {
    width: 100%;
    height: max-content;
  }
}
