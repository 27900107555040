@use "@scss/global.scss" as *;

.create-event-nft-modal {
  width: 720px;

  &-content {
    max-height: 85vh;
    overflow-y: auto;
    padding-right: 10px;
  }
}
