@use "@scss/global.scss" as *;

.nft-faq-section {
  padding-top: 80px;

  .title {
    max-width: 245px;
    width: 100%;
  }

  .list {
    li {
      display: list-item;
      margin-left: 2em;
    }
  }
}
