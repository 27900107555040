@use "@scss/global.scss" as *;

.dropdown {
  position: relative;
  width: max-content;

  &-menu {
    width: max-content;
    padding: 20px 10px;
    border-radius: 10px;
    background: $gradient-3;
    border: 1px solid $gray-1;
    box-shadow: $shadow-2;
    z-index: 200;
  }

  &-menu-item {
    padding: 12px 22px;
    transition: background-color 0.2s ease-in-out;
    width: 100%;
    text-align: left;

    &:hover {
      background-color: $white-8;
      border-radius: 2px;
    }

    &:disabled {
      color: $black-1;
      opacity: 0.4;
      background-color: transparent;
      pointer-events: none;
    }
  }
}
