@use "@scss/global.scss" as *;

.exlorer-nfts-loader {
  display: flex;
  flex-flow: column nowrap;
  gap: 15px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 600px;
}
