@use "@scss/global.scss" as *;

.mobile-guards-modal {
  width: 260px;

  @include mq($md) {
    width: 100%;
    height: 100%;
    justify-content: center !important;
  }
}
