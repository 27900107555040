@use "@scss/global.scss" as *;

.edit-event-page-settings-modal {
  width: 720px;

  &-content {
    max-height: 80vh;
    overflow-y: auto;
    padding-right: 10px;

    .modal-inputs {
      width: 50%;
    }
  }
}
