@use "@scss/global.scss" as *;

.event-page-error {
  position: relative;
  overflow-x: clip;
  margin: 40px 0;
  min-height: 720px;

  &-background {
    position: absolute;
    width: 100%;
    height: 720px;
    display: flex;
    justify-content: center;
  }

  &-content {
    position: relative;
    max-width: 530px;
    margin: auto;
    top: 262px;
  }

  @include mq($md) {
    min-height: 75vh;

    &-content {
      top: 138px;
    }
  }
}
