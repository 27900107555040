@use "@scss/global.scss" as *;

.events-list-layout {
  display: flex;
  margin: 40px 0 100px;
  column-gap: 40px;

  &-sidebar-part {
    max-width: 340px;
    width: 100%;
  }

  &-content-part {
    width: 100%;
  }
}
