@use "@scss/global.scss" as *;

.edit-agreements-modal {
  width: 760px;

  &-content {
    max-height: 80vh;
    overflow-y: auto;
    padding-right: 10px;
  }

  &-agreement {
    margin-top: 30px;
    animation: fadeIn 0.2s ease-out;

    .agreement-controls {
      position: relative;
      bottom: -25px;
    }

    .agreement-inputs {
      width: 100%;

      .input {
        font-size: 15px;
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
