@use "@scss/global.scss" as *;

.copy-text {
  position: relative;
  display: flex;
  width: 100%;
  padding: 10px 12px;
  gap: 10px;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid $white-6;
  background: $white-3;

  &-value {
    flex: 1;

    @include text-ellipsis(1);
    word-break: break-all;
    height: max-content;
  }
}
