@use "@scss/global.scss" as *;

.nav-link {
  font-weight: $light;
  font-size: 15px;
  line-height: 19px;
  padding: 19px 20px 20px;
  min-height: 60px;
  transition: all 0.2s ease-in-out;
  position: relative;
  width: max-content;

  &:hover {
    color: $primary;
  }

  &-active {
    color: $primary;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: $primary;
    }
  }
}
