@use "@scss/global.scss" as *;

.event-page-layout {
  .header-part {
    width: 100%;
    height: max-content;
  }

  .content-part {
    width: 100%;
    min-height: calc(100vh - 356px);
    padding: 0px 15px;
    height: 100%;
    max-width: $xl;
    margin: 0 auto;
  }

  .footer-part {
    width: 100%;
    height: max-content;
  }

  @include mq($md) {
    .footer-part {
      min-height: 272px;
      height: 272px;
    }
  }
}
