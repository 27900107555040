@use "@scss/global.scss" as *;

.claim-nft-layout {
  position: relative;
  background-color: $white-3;
  overflow-x: clip;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  .header-part {
    position: absolute;
    padding: 0px 15px;
    width: 100%;
    max-width: $xl;
    height: max-content;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    display: flex;
    justify-content: space-between;

    .skey-tix-logo-claim {
      width: 160px;
      height: 42px;
    }
  }

  .main-part {
    position: relative;
    width: 100%;
    max-width: $xl;
    margin: 0 auto;
    padding: 0px 15px;
    min-height: 1100px;
    flex-grow: 1;

    .white-ellipse-bg {
      position: absolute;
      background: $white-1;
      border-radius: 50%;
      width: 300%;
      height: 100%;
      min-height: 1100px;
      left: 50%;
      top: -50%;
      transform: translateX(-50%);
    }

    .main-content {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
    }

    .blue-circle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .footer-part {
    width: 100%;
    height: max-content;
  }

  @include mq($md) {
    .header-part {
      top: 18px;

      .skey-tix-logo {
        width: 100px;
        height: 25px;
      }

      .skey-tix-logo-claim {
        width: 100px;
        height: 26px;
      }

      .go-to-poap-btn {
        display: none;
      }
    }

    .main-part {
      min-height: 680px;

      .white-ellipse-bg {
        min-height: 680px;
        top: -55%;
      }
    }
  }
}
