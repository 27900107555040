@use "@scss/global.scss" as *;

.poap-nft-preview-modal {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 30px;
  width: 340px;

  &-content {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 30px;
    width: 100%;

    .nft-image {
      border-radius: 50%;
      width: 100%;
      max-width: 340px;
      aspect-ratio: 1;
    }

    .content-info {
      display: flex;
      flex-flow: column nowrap;
      row-gap: 10px;
      width: 100%;

      .nft-description {
        word-break: break-all;
      }
    }
  }

  @include mq($md) {
    justify-content: space-between;
    width: 100%;
    height: 100%;

    &-content {
      padding-top: 30px;
      row-gap: 60px;

      .nft-image {
        max-width: none;
      }

      .content-info {
        row-gap: 20px;
        text-align: center;
      }
    }

    .button {
      width: 100%;
    }
  }
}
