@use "@scss/global.scss" as *;

.password-button {
  @include flex-center();
  background: transparent;
  width: 35px;
  height: 35px;
  padding: 0;
  border-radius: 50%;
  transition: background-color 0.2s ease-in;

  &:hover {
    background-color: $black-5;
  }
}
