@use "@scss/global.scss" as *;

.explore-nfts-emptyState {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  width: 100%;
  max-width: 530px;
  margin: 0 auto;
  align-items: center;

  .emptyState-animation {
    width: 100%;
    height: 100%;
    max-width: 476px;
    max-height: 476px;
  }

  .emptyState-info {
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
    width: 100%;
    text-align: center;
  }

  @include mq($md) {
    .emptyState-info {
      text-align: left;
    }
  }
}
