@use "@scss/global.scss" as *;

.nft-collection {
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;

  &-title {
    color: $primary;
    font-size: 26px;
    font-weight: 700;
    line-height: 150%;
  }

  &-list {
    display: flex;
    flex-flow: row wrap;
    gap: 40px;
  }

  @include mq($md) {
    &-list {
      gap: 30px;
    }
  }
}
