@use "@scss/global.scss" as *;

.edit-event-page-content-modal {
  width: 920px;

  &-content {
    overflow: hidden auto;
    max-height: 80vh;
    padding-right: 10px;
  }

  .edit-event-page-content-modal-editor {
    .text-editor-content {
      height: 540px;
    }
  }

  @include mq(1920px) {
    &-content {
      max-height: 650px;
    }

    .edit-event-page-content-modal-editor {
      .text-editor-content {
        height: 490px;
      }
    }
  }
}
