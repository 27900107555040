@use "@scss/global.scss" as *;

.share-button {
  display: flex;
  min-width: 100px;
  width: 100%;
  height: 42px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: $shadow-10;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: $shadow-11;
  }

  &-disabled {
    opacity: 0.4;
    cursor: default;
    box-shadow: none;
    pointer-events: none;
  }

  &-black {
    background: #000;
  }

  &-facebook {
    background: #0866ff;
  }

  &-linkedin {
    background: #0a66c2;
  }

  &-telegram {
    background: #29a9ea;
  }
}
