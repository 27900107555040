@use "@scss/global.scss" as *;

.event-header {
  width: 100%;
  height: 260px;

  &-name {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    word-break: break-all;
  }
}
