@use "@scss/global.scss" as *;

.search-input {
  width: 100%;
  font-size: 15px;

  &-label {
    display: inline-block;
    font-size: 1em;
    font-weight: $light;
    line-height: 150%;
    padding-bottom: 3px;
    padding-left: 2px;

    .label-asterisk {
      font-size: 1em;
      color: $danger-1;
      font-weight: $bold;
    }
  }

  &-description {
    font-size: 0.8em;
    line-height: 150%;
    color: $black-2;
    margin-bottom: 2px;
  }

  .search-input-inputWrapper {
    position: relative;

    .input-padding-normal {
      padding: 8px 14px;
      height: 42px;
    }

    .input-padding-big {
      padding: 12px 14px;
      height: 47px;
    }

    input {
      font-size: 1em;
      font-weight: $light;
      color: $black-1;
      letter-spacing: 0.01em;
      border-radius: 8px;
      border: 1px solid $white-5;
      background-color: $white-3;
      caret-color: $black-1;
      width: 100%;
      transition: all 0.2s ease-in-out;
      outline: none;
      padding-right: 42px !important;

      &:focus {
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: $white-1;
        box-shadow: $shadow-8;
      }

      &::placeholder {
        font-weight: $light;
        color: $white-6;
      }

      &:disabled {
        background-color: $black-5;
        opacity: 0.6;
        cursor: default;
      }

      &:not(:disabled).input-error {
        color: $danger-1;
        border: 1px solid $danger-1;
        caret-color: $danger-1;

        &:focus {
          caret-color: auto;
        }
      }
    }

    input[type="search"]::-webkit-search-cancel-button {
      position: relative;
      cursor: pointer;
      -webkit-appearance: none;
      height: 12px;
      width: 12px;
      left: 5px;
      background: url("../../../../public/images/icon-close.svg") no-repeat 50% 50%;
    }

    input:focus + .input-adornment .input-adornment-icon {
      path {
        stroke: $black-1 !important;
      }
    }

    input:disabled + .input-adornment .input-adornment-icon {
      path {
        opacity: 0.3;
        stroke: $black-1 !important;
      }
    }

    .input-adornment {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-errorText {
    margin-top: 6px;
    margin-left: 2px;
    line-height: 120%;
    font-size: 0.85em;
    font-weight: $light;
    color: $danger-1;
  }
}
