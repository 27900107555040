@use "@scss/global.scss" as *;

.badge-status {
  border-radius: 25px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: $white-1;
  padding: 3px 12px;

  &-started {
    background: $orange;
  }
  &-ended {
    background: $black-1;
  }
}
