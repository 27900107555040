@use "@scss/global.scss" as *;

$ticket-primary: #000000;

.ticket {
  position: relative;
  padding: 20px;
  background: $white-1;
  border: 1px solid $gray-1;
  border-radius: 10px;
  width: 340px;
  height: 599px;
  overflow: clip;

  &-content {
    position: relative;
  }

  &-background-demo {
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    column-gap: 15px;
    top: 250px;
    left: -120px;
    color: $white-1;
    width: 770px;
    height: 32px;
    transform: rotate(45deg);
    background-color: rgba(#000000, 0.1);
    z-index: 0;
    font-weight: 700;
    font-size: 13.6px;
    line-height: 17px;
    text-transform: uppercase;
  }

  &-organization {
    height: 55px;

    .organization-header {
      font-weight: $light;
      font-size: 10px;
      line-height: 12px;
      color: $ticket-primary;
    }

    .organization-name {
      @include text-ellipsis(2);
      font-weight: $light;
      font-size: 15px;
      line-height: 18px;
      color: $ticket-primary;
    }

    .organization-logo {
      width: 50px;
      height: auto;
      max-height: 50px;
    }
  }

  &-horizontal-rule {
    margin-top: 17px;
    height: 1px;
    background-color: $ticket-primary;
    opacity: 0.1;
    width: 100%;
  }

  &-event-name {
    margin-top: 12px;
    font-size: 15px;
    font-weight: $light;
    line-height: 150%;
    color: $ticket-primary;
    height: 111px;

    .event-title {
      @include text-ellipsis(3);
      font-weight: $medium;
      font-size: 15px;
      line-height: 18px;
      color: $ticket-primary;
    }

    .event-link {
      line-height: 150%;
    }
  }

  &-date-location {
    margin-top: 13px;
    height: 78px;

    .event-location-name {
      @include text-ellipsis(2);
      line-height: 150%;
      width: auto;
    }
  }

  &-qr-code {
    margin-top: 20px;
  }

  &-footer {
    margin-top: 20px;

    .footer-skey-branding {
      font-weight: $light;
      font-size: 10px;
      line-height: 12px;
      color: rgba(18, 14, 47, 0.3);
    }

    .ticket-demo-mark {
      position: relative;
      text-transform: uppercase;
      background-color: $danger-2;
      color: $white-1;
      padding: 3px 9px 3px 3px;
      font-weight: $bold;
      font-size: 13.6px;
      line-height: 17px;

      &::after {
        content: "";
        position: absolute;
        left: 0%;
        top: 0;
        bottom: 0;
        width: 15px;
        height: 100%;
        background: $danger-2;
        transform: translateX(-100%) skewY(0.001deg);
        -webkit-clip-path: polygon(100% 0, 100% 100%, 0 50%);
        clip-path: polygon(100% 0%, 100% 100%, 0% 50%);
      }
    }
  }
}
