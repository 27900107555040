@use "@scss/global.scss" as *;

.organization-guard-modal {
  width: 400px;
  min-height: 384px;

  @include mq($md) {
    width: 100%;
    height: 100%;
    justify-content: center !important;
  }
}
