@use "@scss/global.scss" as *;

.event-submissions {
  margin: 32px 0 40px;

  &-search-input {
    position: relative;
    min-width: 218px;
    max-width: 300px;
    left: 45px;
  }

  .table {
    margin-top: 24px;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    row-gap: 10px;

    &-row {
      display: flex;
      flex-flow: row nowrap;
      column-gap: 20px;
      height: max-content;
      border-radius: 10px;
      padding: 10px 5px;
    }

    &-row-ticket-used {
      color: $dark-blue;
    }

    &-row-checked {
      background-color: rgba(0, 0, 0, 0.05);
      box-shadow: 0px 15.9009px 23.8514px rgba(0, 0, 0, 0.02), 0px 3.97523px 7.95046px rgba(0, 0, 0, 0.02);
    }

    &-header {
      @extend .table-row;
      padding: 0px 5px;

      button {
        color: unset;
        font-size: unset;
      }
    }

    .row-cell {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      min-height: 60px;

      font-weight: $light;
      font-size: 15px;
      line-height: 30px;
      word-break: break-all;
    }

    .header-cell {
      @extend .row-cell;
      min-height: 32px;
      font-size: 13px;
      line-height: 16px;
      color: $black-2;
    }

    .cell-checkbox {
      max-width: 20px;

      .checkbox {
        margin-top: 5px;
      }
    }

    .cell-memebr-data {
      max-width: 325px;
    }

    .cell-notes {
      max-width: 120px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: normal;
    }

    .cell-dates {
      max-width: 140px;
    }

    .cell-actions {
      max-width: 125px;

      .group {
        margin-top: -2.5px;
      }
    }
  }
}
