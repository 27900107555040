@use "@scss/variables.scss" as *;

.spinner {
  display: flex;

  &-circle {
    animation: spin-right 0.5s linear infinite;
  }
}

@keyframes spin-right {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
