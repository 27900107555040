@use "@scss/global.scss" as *;

.pagination {
  display: flex;
  list-style-type: none;
  column-gap: 10px;

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: $white-1;
    border: 1px solid $white-4;
    border-radius: 5px;
    letter-spacing: 0.01em;
    color: $primary;
    font-weight: $light;
    font-size: 15px;
    line-height: 150%;
    transition: background-color 0.2s ease-in-out;

    &.pagination-dots:hover {
      background-color: $white-1;
      cursor: default;
    }

    &:hover {
      background-color: $white-4;
      cursor: pointer;
    }

    &.selected {
      background: $primary;
      color: $white-1;
    }

    &.disabled {
      pointer-events: none;
      background-color: $white-4;

      &:hover {
        background-color: $white-1;
        cursor: default;
      }
    }
  }
}
