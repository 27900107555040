@use "@scss/global.scss" as *;

.text-editor-custom-utils {
  position: relative;

  &-modal {
    position: absolute;
    width: 500px;
    height: max-content;
    background: $white-1;
    border: 1px solid $gray-1;
    box-shadow: $shadow-3;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
}
