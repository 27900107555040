@use "@scss/global.scss" as *;

.textarea {
  width: 100%;
  font-size: 16px;

  textarea {
    height: 100%;
    width: 100%;
    padding: 0.625em 1em;
    border-radius: 5px;
    border: 1px solid $white-5;
    background: $white-1;
    caret-color: $primary;
    font-family: "Oxygen", sans-serif;
    font-size: 1em;
    font-weight: $light;
    color: $black-1;
    line-height: 1.6;
    letter-spacing: 0.01em;
    outline: none;
    transition: border-color 0.2s ease-in-out;
    resize: none;

    &:focus {
      border-color: $primary;
    }

    &::placeholder {
      font-weight: $light;
      color: $white-6;
    }

    &:disabled {
      background-color: $black-5;
      opacity: 0.6;
      cursor: default;
    }

    &.textarea-error {
      color: $danger-1;
      border-color: $danger-1;
      caret-color: $danger-1;

      &:focus {
        border-color: $primary;
        caret-color: auto;
      }

      &::placeholder {
        color: $danger-1;
      }
    }
  }

  &-label {
    font-size: 1em;
    font-weight: $light;
    line-height: 1.55;
    display: inline-block;
    margin-bottom: 6px;

    .label-asterisk {
      color: $danger-1;
      font-weight: $bold;
      font-size: 1em;
    }
  }

  &-description {
    font-size: 0.85em;
    line-height: 1.55;
    color: $black-2;
    display: block;
    margin-bottom: 2px;
  }

  &-char-counter {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: $black-2;
    font-size: 12px;
  }

  &-error-text {
    margin-top: 4px;
    line-height: 1.2;
    font-size: 0.85em;
    font-weight: $light;
    color: $danger-1;
  }
}
