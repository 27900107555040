@use "./variables.scss" as *;

@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");

hr,
a,
button,
div,
span,
label,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
}

h1 {
  font-weight: $bold;
  font-size: 25px;
  line-height: 32px;
}

h2 {
  font-weight: $light;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.01em;
}

h3 {
  font-weight: $bold;
  font-size: 15px;
  line-height: 1.5;
}

p,
.p0 {
  font-weight: $light;
  font-size: 15px;
  line-height: 1.5;
}

.p1 {
  font-weight: $light;
  font-size: 13px;
  line-height: 1.5;
}

.p2 {
  font-weight: $ultra-light;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
}

.p4 {
  font-weight: $light;
  font-size: 15px;
  line-height: 19px;
}

button {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

a {
  font-weight: $light;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.03em;
}

.light {
  font-weight: $light;
}

.regular {
  font-weight: $regular;
}

.medium {
  font-weight: $medium;
}

.bold {
  font-weight: $bold;
}

input {
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: 0.6px;
  font-weight: $light;
  border: none;
  outline: none;
}

.primary {
  color: $primary;
}
.bg-primary {
  background-color: $primary;
}

.dark-blue {
  color: $dark-blue;
}
.dark-blue-bg {
  background-color: $dark-blue;
}

.black-1 {
  color: $black-1;
}
.black-1-bg {
  background-color: $black-1;
}

.black-2 {
  color: $black-2;
}
.black-2-bg {
  background-color: $black-2;
}

.black-3 {
  color: $black-3;
}
.black-3-bg {
  background-color: $black-3;
}

.black-4 {
  color: $black-4;
}
.black-4-bg {
  background-color: $black-4;
}

.black-5 {
  color: $black-5;
}
.black-5-bg {
  background-color: $black-5;
}

.black-6 {
  color: $black-6;
}
.black-6-bg {
  background-color: $black-6;
}

.white-1 {
  color: $white-1;
}
.white-1-bg {
  background-color: $white-1;
}

.white-2 {
  color: $white-2;
}
.white-2-bg {
  background-color: $white-2;
}

.white-3 {
  color: $white-3;
}
.white-3-bg {
  background-color: $white-3;
}

.white-4 {
  color: $white-4;
}
.white-4-bg {
  background-color: $white-4;
}

.white-5 {
  color: $white-5;
}
.white-5-bg {
  background-color: $white-5;
}

.white-6 {
  color: $white-6;
}
.white-6-bg {
  background-color: $white-6;
}

.white-7 {
  color: $white-7;
}
.white-7-bg {
  background-color: $white-7;
}

.white-8 {
  color: $white-8;
}
.white-8-bg {
  background-color: $white-8;
}

.gray-1 {
  color: $gray-1;
}
.gray-1-bg {
  background-color: $gray-1;
}

.gray-2 {
  color: $gray-2;
}
.gray-2-bg {
  background-color: $gray-2;
}

.gray-3 {
  color: $gray-3;
}
.gray-3-bg {
  background-color: $gray-3;
}

.gray-4 {
  color: $gray-4;
}
.gray-4-bg {
  background-color: $gray-4;
}

.success {
  color: $success;
}
.success-bg {
  background-color: $success;
}

.danger-1 {
  color: $danger-1;
}
.danger-1-bg {
  background-color: $danger-1;
}

.danger-2 {
  color: $danger-2;
}
.danger-2-bg {
  background-color: $danger-2;
}

.warning {
  color: $warning;
}
.warning-bg {
  background-color: $warning;
}

.info {
  color: $info;
}
.info-bg {
  background-color: $info;
}

.orange {
  color: $orange;
}
.orange-bg {
  background-color: $orange;
}

// .gradient-text {
//   color: $primary;
//   background: $primary;
//   background-image: $gradient-2;
//   background-clip: text;
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   -moz-background-clip: text;
//   -moz-text-fill-color: transparent;
// }
