@use "@scss/global.scss" as *;

.auth-layout {
  .sidebar-part,
  .content-part {
    height: 100%;
    width: 50%;
  }

  .sidebar-part {
    position: fixed;
    left: 0;
    top: 0;
  }

  .content-part {
    margin-left: 50%;
    position: relative;
    overflow-x: hidden;
  }

  &-content {
    padding: 40px 20px;
    max-width: 650px;
    margin-left: 80px;
    min-height: 100vh;
  }

  @include mq($md) {
    .sidebar-part {
      display: none;
    }

    .content-part {
      margin: 0 auto;
      width: 100%;
    }
  }
}
