@use "@scss/global.scss" as *;

.auth-sidebar {
  background-color: $white-1;
  height: 100vh;
  position: relative;

  &-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-info {
    position: absolute;
    bottom: 33px;
    left: 40px;
  }
}
