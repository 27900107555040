@use "@scss/global.scss" as *;

.switch-input-wrapper {
  .switch-input {
    display: flex;
    column-gap: 10px;
    align-items: flex-start;

    &-switch {
      appearance: none;
      position: relative;
      display: flex;
      align-items: center;
      min-width: 26px;
      max-width: 26px;
      height: 18px;
      border-radius: 20px;
      border: 2px solid $white-5;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.1, 1.4);
      cursor: pointer;

      &::after {
        content: "";
        position: relative;
        display: block;
        left: 2px;
        width: 10px;
        height: 10px;
        background: $white-5;
        border-radius: 50%;
        box-sizing: content-box;
        transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition-property: left, margin, padding;
      }

      &:active:after {
        padding-right: 6px;
      }

      &:checked:active:after {
        margin-left: -6px;
      }

      &:checked {
        border: 2px solid $primary;
      }

      &:checked:after {
        background: $primary;
        left: 10px;
      }

      &:disabled {
        opacity: 0.3;
        pointer-events: none;
      }

      &:disabled:active:after {
        padding-right: 0;
        margin-left: 0;
      }
    }

    .label-text-normal {
      font-weight: $light;
      font-size: 15px;
      line-height: 19px;
    }

    .label-text-small {
      font-weight: $light;
      font-size: 13px;
      line-height: 20px;
    }

    .label-disabled {
      opacity: 0.3;
    }

    .label-asterisk {
      color: $danger-1;
      font-weight: $bold;
      font-size: 15px;
      margin-left: 5px;
    }
  }

  .switch-input-error-text {
    line-height: 1.2;
    font-size: 14px;
    font-weight: $light;
    color: $danger-1;
    margin-top: 5px;
    margin-left: 32px;
  }
}
