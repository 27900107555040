@use "@scss/global.scss" as *;

.event-page-sidebar {
  width: 100%;

  &-image {
    img {
      max-width: 340px;
      // max-height: 340px;
    }
  }

  .button-disabled-info {
    width: 100%;
    text-align: center;
    font-weight: $bold;
    color: $danger-1;
  }

  .button-signup {
    width: 100%;
  }

  .mobile-signup-button {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    background: $white-1;
    box-shadow: $shadow-6;
    padding: 10px;
    z-index: 1;
  }

  .date-name {
    width: 100px;
  }

  &-organizer-logo-box {
    width: 180px;
    height: 180px;
    background: $white-1;
    border: 1px solid $gray-1;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;

    img {
      width: 120px;
      max-height: 120px;
      object-fit: scale-down;
    }
  }

  @include mq($md) {
    &-image {
      img {
        max-width: 100%;
      }
    }
  }
}
