@use "@scss/global.scss" as *;

.events-nav-list {
  width: 100%;

  &-create-event {
    border-radius: 10px;
    padding: 17px 20px;
    cursor: pointer;
    width: 100% !important;
    background: $gray-4;
    border: 1px solid $gray-3;
    border-radius: 10px;

    .craete-event-icon {
      width: 18px;
      height: 18px;
    }

    &:not(.event-item-active):hover {
      background: $gray-2;
      box-shadow: $shadow-1;
    }
  }
}
