@use "@scss/global.scss" as *;

.event-gates-settings {
  margin-top: 50px;

  &-title {
    margin-left: 20px;
  }

  &-content {
    margin-top: 32px;
    padding: 30px 20px 20px;
    background: $white-1;
    border: 1px solid $gray-1;
    border-radius: 10px;
  }
}
