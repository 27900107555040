@use "@scss/global.scss" as *;

.event-page {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 50px 0 237px;

  &-content {
    width: 100%;
    max-width: 720px;

    .event-description {
      margin-top: 35px;
    }
  }

  &-sidebar {
    width: 100%;
    max-width: 340px;
  }

  @include mq($md) {
    flex-flow: column nowrap;
    row-gap: 40px;

    &-content {
      max-width: 100%;
    }

    &-sidebar {
      width: 100%;
      max-width: 100%;
    }
  }
}
