$primary: #5c44ec;
$dark-blue: #2c289a;
$black-1: #120e2f;
$black-2: rgba(18, 14, 47, 0.5);
$black-3: rgba(18, 14, 47, 0.1);
$black-4: rgba(34, 34, 51, 0.2);
$black-5: rgba(34, 34, 51, 0.05);
$black-6: #0b091c;

$white-1: #ffffff;
$white-2: #d9d9d9;
$white-3: #fafafc;
$white-4: #eeeef1;
$white-5: #ced4da;
$white-6: #adb5bd;
$white-7: #eef0f2;
$white-8: #f8f8fa;

$gray-1: rgba(0, 0, 0, 0.1);
$gray-2: rgba(0, 0, 0, 0.03);
$gray-3: rgba(0, 0, 0, 0.06);
$gray-4: rgba(0, 0, 0, 0.02);

$orange: #ffaf39;

$success: #22b814;
$danger-1: #f03e3e;
$danger-2: #ff6b6b;
$warning: #ffdf00;
$info: #18afff;

$gradient-1: linear-gradient(270.16deg, #3495ff 0.02%, #5c44ec 97.16%);
$gradient-2: linear-gradient(90deg, #3495ff 0%, #5c44ec 100.01%);
$gradient-3: linear-gradient(159.1deg, rgba(255, 255, 255, 0.1) -0.09%, rgba(255, 255, 255, 0) 171.38%), #ffffff;
$gradient-4: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
$gradient-5: linear-gradient(270deg, #ff6b6b 0.02%, #ff2121 97.16%);

$shadow-1: 0px 15.9009px 23.8514px rgba(0, 0, 0, 0.02), 0px 3.97523px 7.95046px rgba(0, 0, 0, 0.02);

$shadow-2: 8px 8px 40px rgba(18, 14, 47, 0.02), 0px 46.8086px 70.2128px rgba(0, 0, 0, 0.04),
  -4px 11.7021px 23.4043px rgba(0, 0, 0, 0.02), 0px 0px 2.92553px rgba(0, 0, 0, 0.04);

$shadow-3: 0px 17px 17px rgba(0, 0, 0, 0.04), 0px 36px 28px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.05);

$shadow-4: 9.77335px 19.7099px 19px rgba(0, 0, 0, 0.07), 4.94776px 9.97816px 8.28281px rgba(0, 0, 0, 0.04725),
  1.95467px 3.94199px 3.0875px rgba(0, 0, 0, 0.035), 0.427584px 0.86231px 1.09844px rgba(0, 0, 0, 0.02275);

$shadow-5: 0px 12px 17px rgba(0, 0, 0, 0.02), 0px 4.62222px 5.41481px rgba(0, 0, 0, 0.0121481),
  0px 0.977778px 1.38519px rgba(0, 0, 0, 0.00785185);

$shadow-6: 0px -12px 17px rgba(0, 0, 0, 0.02), 0px -4.62px 5.41481px rgba(0, 0, 0, 0.0121481),
  0px -0.98px 1.38519px rgba(0, 0, 0, 0.00785185);

$shadow-7: 0px 0.97778px 1.38519px 0px rgba(0, 0, 0, 0.01), 0px 4.62222px 5.41481px 0px rgba(0, 0, 0, 0.01),
  0px 12px 17px 0px rgba(0, 0, 0, 0.02);

$shadow-8: 0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 36px 28px -7px rgba(0, 0, 0, 0.05),
  0px 17px 17px -7px rgba(0, 0, 0, 0.04);

$shadow-9: 0.42758px 0.86231px 1.09844px 0px rgba(0, 0, 0, 0.02), 1.95467px 3.94199px 3.0875px 0px rgba(0, 0, 0, 0.04),
  4.94776px 9.97816px 8.28281px 0px rgba(0, 0, 0, 0.05), 9.77335px 19.70994px 19px 0px rgba(0, 0, 0, 0.07);

$shadow-10: -1px -1px 2px 0px rgba(229, 229, 229, 0.5) inset, 1px 1px 2px 0px rgba(255, 255, 255, 0.3) inset;

$shadow-11: 4px 4px 10px 0px rgba(229, 229, 229, 0.9), -4px -4px 8px 0px rgba(255, 255, 255, 0.9),
  4px -4px 8px 0px rgba(229, 229, 229, 0.2), -4px 4px 8px 0px rgba(229, 229, 229, 0.2),
  -1px -1px 2px 0px rgba(229, 229, 229, 0.5) inset, 1px 1px 2px 0px rgba(255, 255, 255, 0.3) inset;

// BREAKPOINTS
$xs: 320px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1130px; // 1100px + left/right padding 15px
$xxl: 1440px;

$breakpoints: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl
);
// BREAKPOINTS

$size-dx: 8px;

$size-1: $size-dx;
$size-2: $size-dx * 2;
$size-3: $size-dx * 3;
$size-4: $size-dx * 4;
$size-5: $size-dx * 5;
$size-6: $size-dx * 6;
$size-7: $size-dx * 7;
$size-8: $size-dx * 8;
$size-9: $size-dx * 9;
$size-10: $size-dx * 10;

$size-variants: (
  1: $size-1,
  2: $size-2,
  3: $size-3,
  4: $size-4,
  5: $size-5,
  6: $size-6,
  7: $size-7,
  8: $size-8,
  9: $size-9,
  10: $size-10
);

$display-variants: (
  b: block,
  f: flex,
  n: none
);

$ultra-light: 300;
$light: 400;
$regular: 500;
$medium: 600;
$bold: 700;
