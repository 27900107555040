@use "@scss/global.scss" as *;

.input {
  width: 100%;
  font-size: 16px;

  input {
    font-size: 1em;
    font-weight: $light;
    color: $black-1;
    padding: 0.5em 0.875em;
    letter-spacing: 0.01em;
    border-radius: 0.5em;
    border: 1px solid $white-5;
    background-color: $white-1;
    caret-color: $primary;
    width: 100%;
    height: 42px;
    transition: border-color 0.2s ease-in-out;
    outline: none;
    // position: relative;

    &:focus {
      border-color: $primary;
    }

    &::placeholder {
      font-weight: $light;
      color: $white-6;
    }

    &:disabled {
      background-color: $black-5;
      opacity: 0.6;
      cursor: default;
    }

    &:not(:disabled).input-error {
      color: $danger-1;
      border: 1px solid $danger-1;
      caret-color: $danger-1;

      &:focus {
        border: 1px solid $primary;
        caret-color: auto;
      }
    }
  }

  .input-wrapper {
    position: relative;

    input {
      padding-right: 42px;
    }

    .input-adornment {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 8px;

    .input-label {
      font-size: 1em;
      font-weight: $light;
      line-height: 1.55;
      display: inline-block;
      margin-bottom: 6px;

      .label-asterisk {
        color: $danger-1;
        font-weight: $bold;
        font-size: 1em;
      }
    }

    .input-char-counter {
      color: $black-2;
      font-size: 12px;
      align-self: flex-end;
      margin: 0 5px 5px 0;

      &--error {
        color: $danger-1;
      }
    }
  }

  &-description {
    font-size: 0.85em;
    line-height: 1.55;
    color: $black-2;
    display: block;
    margin-top: 4px;
  }

  &-error-text {
    margin-top: 4px;
    line-height: 1.2;
    font-size: 0.85em;
    font-weight: $light;
    color: $danger-1;
  }
}
