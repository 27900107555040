@use "@scss/global.scss" as *;

.event-basic-info {
  &-title {
    margin-left: 20px;
  }

  &-content {
    margin-top: 36px;
    padding: 30px 20px 20px;
    background: $white-1;
    border: 1px solid $gray-1;
    border-radius: 10px;

    .content-value {
      word-break: break-all;
    }
  }

  &-hr {
    margin: 40px 0;
    height: 1px;
    background-color: $black-1;
    opacity: 0.1;
  }
}
