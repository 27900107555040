@use "@scss/global.scss" as *;

.explore-nfts-page {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
  padding: 50px 0;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 100px;

    .title {
      font-size: 38px;
      font-weight: 300;
      letter-spacing: -0.48px;
    }

    .saerch-nft {
      max-width: 340px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex: 1;

    .explore-nfts-list {
      padding-top: 50px;
      display: flex;
      flex-flow: column nowrap;
      height: max-content;
      gap: 50px;
      width: 100%;
    }
  }

  @include mq($md) {
    &-header {
      flex-flow: column nowrap;
      gap: 30px;
      height: max-content;
      align-items: flex-start;

      .title {
        font-size: 28px;
        letter-spacing: -0.3px;
      }

      .saerch-nft {
        max-width: 520px;
      }
    }

    &-content {
      padding-top: 40px;
    }
  }
}
