@use "@scss/global.scss" as *;

.ticket-preview-modal {
  width: 344px;
  min-height: 570px;

  &-image {
    width: 340px;
    height: 599px;
  }
}
