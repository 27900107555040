@use "@scss/global.scss" as *;

.event-page-header {
  &-horizontalRule {
    height: 1px;
    background-color: $black-1;
    opacity: 0.1;
    width: 100%;
  }
}
