@use "@scss/global.scss" as *;

.text-editor {
  position: relative;
  border: 1px solid $gray-1;
  border-radius: 8px;
  width: 100%;

  &-content {
    height: 490px;
    overflow: hidden auto;
  }
}

/* Basic editor content styles */

.ProseMirror {
  outline: 0;
  padding: 15px;

  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol {
    color: $black-1;
  }

  h1 {
    font-weight: $bold;
    font-size: 25px;
    line-height: 1.5;
  }

  h2 {
    font-weight: $bold;
    font-size: 22px;
    line-height: 1.5;
  }

  h3 {
    font-weight: $bold;
    font-size: 20px;
    line-height: 1.5;
  }

  h4 {
    font-weight: $bold;
    font-size: 18px;
    line-height: 1.5;
  }

  p {
    font-weight: $light;
    font-size: 15px;
    line-height: 1.5;
  }

  a {
    font-size: 15px;
    font-weight: $light;
    line-height: 1.7;
    letter-spacing: 0.01em;
    color: $primary;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $black-1;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  hr {
    height: 1px;
    background-color: $primary;
    opacity: 0.1;
    width: 100%;
  }

  ul,
  ol {
    padding: 0 1em;
    margin: 0;
  }

  blockquote {
    padding: 20px;
    background-color: $gray-2;
    border-radius: 0 5px 5px 0;
    border: 1px solid $gray-2;
    border-left: 5px solid rgba($primary, 0.7);
  }
}
