@use "@scss/global.scss" as *;

.image-cropper {
  position: relative;
  width: 100%;
  margin-bottom: 16px;

  .cropper {
    background: none;
    height: 400px;
  }

  .cropper-overlay {
    transition: 0.5s;

    &--faded {
      color: rgba(black, 0.3);
    }
  }
}
