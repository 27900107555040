@use "@scss/global.scss" as *;

.received-nft-preview-before {
  height: 141px;

  object {
    width: 245px;
    height: 109px;
  }

  @include mq($md) {
    width: 250px;
    height: 66px;

    object {
      width: 150px;
      height: 66px;
    }
  }
}

.received-nft-preview {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 141px;

  &-fireworks {
    position: absolute;
    bottom: 10px;
    display: none;
  }

  &-bg {
    position: absolute;
    width: 245px;
    height: 109px;
  }

  .nft-image {
    position: relative;
    border-radius: 50%;
    width: 141px;
    height: 141px;
    display: none;
    animation: fadeZoom 0.8s cubic-bezier(1, 0, 0.21, 1);
  }

  .show-element {
    display: block;
  }

  @include mq($md) {
    width: 250px;
    height: 66px;

    &-fireworks {
      bottom: -20px;
    }

    &-bg {
      width: 150px;
      height: 66px;
    }

    .nft-image {
      width: 86px;
      height: 86px;
    }
  }

  @keyframes fadeZoom {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}
