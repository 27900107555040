@use "@scss/global.scss" as *;

.demo-mark {
  position: relative;

  &-flag {
    position: absolute;
    top: 40%;
    left: 115%;
    transform: translateY(-40%);
    background-color: $danger-2;
    color: $white-1;
    text-transform: uppercase;
    width: max-content;
    padding: 3px 9px;
    margin: 0;

    &::after {
      content: "";
      position: absolute;
      left: 0%;
      transform: translateX(-100%) skewY(0.001deg);
      top: 0;
      bottom: 0;
      width: 15px;
      height: 100%;
      background: $danger-2;
      -webkit-clip-path: polygon(100% 0, 100% 100%, 0 50%);
      clip-path: polygon(100% 0%, 100% 100%, 0% 50%);
    }
  }
}
