@use "@scss/global.scss" as *;

.select {
  width: 100%;

  &-options {
    display: flex;
    flex-direction: column;
    background-color: $white-1;
    border-radius: 8px;
    border: 1px solid $white-5;
    box-shadow: $shadow-3;
    padding: 5px;
    max-height: 150px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: $white-1;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: $white-2;
    }
  }

  .empty-options {
    padding: 9px 0px;
    height: 40px;
    text-align: center;
  }
}
