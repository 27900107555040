@use "@scss/global.scss" as *;

.event-nft-access-denied {
  max-width: 530px;
  margin: 50px auto;

  .premium {
    color: rgba($black-1, 0.4);
  }
}
