@use "@scss/global.scss" as *;

.header {
  background: $white-1;

  &-content {
    max-width: $xl;
    padding: 30px 15px 20px;
    margin: auto;
  }
}
