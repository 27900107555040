@use "@scss/global.scss" as *;

.organization-data {
  padding: 60px 0 40px;
  display: flex;
  width: 100%;
  gap: 60px;
  justify-content: space-between;

  &-base-info {
    min-width: 300px;
  }

  &-logo {
    min-width: 300px;

    .logo-preview {
      width: 200px;
      height: 200px;
    }
  }
}
