@use "@scss/global.scss" as *;

.tickets-table {
  margin: 50px 0 40px;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  row-gap: 10px;

  &-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    column-gap: 10px;
    height: max-content;
    min-height: 40px;
  }

  &-header {
    font-weight: $light;
    font-size: 13px;
    line-height: 16px;
    color: $black-2;
  }

  .row-cell {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    font-weight: $light;
    min-height: 40px;
    word-break: break-all;
  }

  .header-cell {
    @extend .row-cell;
    font-weight: $light;
    font-size: 13px;
    line-height: 16px;
    color: $black-2;
  }

  .cell-tickets-number {
    max-width: 120px;
  }
  .cell-termOfValidity {
    max-width: 140px;
  }
  .cell-comments {
    max-width: 340px;
  }
  .cell-validate {
    max-width: 150px;
  }
  .cell-actions {
    max-width: 140px;
  }
}
