@use "@scss/global.scss" as *;

.nft-card {
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 245px;
  width: 100%;
  height: 315px;

  &-image-box {
    height: max-content;
    width: max-content;
    position: relative;
    cursor: pointer;

    .nft-image {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      aspect-ratio: 1;
    }

    .nft-image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      transition: opacity 0.2s ease-in-out;
      background-color: rgba(18, 14, 47, 0.4);
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover .nft-image-overlay {
      opacity: 1;
      cursor: pointer;
    }
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    gap: 5px;

    .nft-title {
    }

    .nft-description {
      @include text-ellipsis(2);
      word-break: break-all;
      height: 45px;
    }
  }

  &-buttons {
    display: flex;
    gap: 5px;
  }

  @include mq($md) {
    min-width: 130px;
    max-width: calc(50% - 20px);
    height: max-content;

    &-image-box {
      .nft-image,
      .nft-image-overlay {
        width: 130px;
        height: 130px;
      }
    }

    &-content {
      .nft-description {
        @include text-ellipsis(4);
        height: max-content;
      }
    }
  }

  @include mq($xs - 1) {
    min-width: 100%;
    align-items: center;

    &-image-box {
      .nft-image,
      .nft-image-overlay {
        width: 240px;
        height: 240px;
      }
    }
  }
}
