@use "@scss/global.scss" as *;

.activate-account-page {
  min-height: 400px;

  .info-message {
    max-width: 400px;
    white-space: pre-wrap;
  }
}
