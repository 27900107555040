@use "@scss/global.scss" as *;

.api-settings {
  padding-top: 80px;

  &-content {
    overflow-x: auto;
  }

  .title {
    max-width: 245px;
    width: 100%;
  }

  hr {
    height: 1px;
    background-color: $black-1;
    opacity: 0.3;
  }
}
