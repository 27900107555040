@use "@scss/global.scss" as *;

.edit-nft {
  margin-top: 50px;

  &-title {
    margin-left: 20px;
  }

  &-content {
    margin-top: 32px;
  }

  &-edit {
    padding: 30px 20px 20px;
    background: $white-1;
    border: 1px solid $gray-1;
    border-radius: 10px;
    flex: 1;
    min-height: 340px;
  }

  &-preview {
    padding: 20px;
    background: $white-1;
    border: 1px solid $gray-1;
    border-radius: 10px;
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    &-image {
      border-radius: 50%;
      height: 300px;
      width: 300px;
      aspect-ratio: 1;
      border: 1px solid #0000001a;
    }
  }
}
