@use "@scss/global.scss" as *;

.utils-buttons {
  left: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;

  &-upload-input {
    display: none;
  }
}
