@use "@scss/global.scss" as *;

.reset-password {
  max-width: 530px;
  width: 100%;

  &-input {
    max-width: 440px;
  }
}
