@use "@scss/global.scss" as *;

.status-badge {
  width: 100%;
  background: $black-1;
  border-radius: 10px;
  padding: 15px 20px;

  svg {
    width: 100%;
    max-width: 20px;
    margin-top: 2px;

    @include mq($sm) {
      margin-top: 5px;
      align-self: flex-start;
    }
  }
}
