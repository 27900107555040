.scroll-shadow-wrapper {
  position: relative;
  width: 100%;

  --shadow-top-opacity: 0;
  --shadow-bottom-opacity: 0;
  --scroll-shadow-size: 0px;
  --scroll-top: 0px;
  --scroll-bottom: 0px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    z-index: 1;
    left: 0;
    transition: 0.3s;
    background-size: 100%;
    background-repeat: no-repeat;
    pointer-events: none;
  }

  &::after {
    opacity: var(--shadow-top-opacity);
    background: radial-gradient(farthest-side at 50% 0%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
    height: min(var(--scroll-top), var(--scroll-shadow-size));
    top: 0;
  }

  &::before {
    opacity: var(1);
    background: radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
    height: min(var(--scroll-bottom), var(--scroll-shadow-size));
    bottom: 0;
  }
}
