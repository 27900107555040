@use "@scss/global.scss" as *;

.faq-section {
  padding-top: 120px;

  .title {
    max-width: 245px;
    width: 100%;
  }
}
