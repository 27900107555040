@use "@scss/global.scss" as *;

.login-page {
  max-width: 530px;
  width: 100%;

  &-title {
    font-size: 17px;
    font-weight: $light;
    line-height: 1.5;
    letter-spacing: -0.01em;
  }

  &-input {
    max-width: 440px;
  }
}
