@use "@scss/global.scss" as *;

.nft-footer {
  &-socials {
    width: 100%;
    padding: 30px 15px;
    background: rgba(#000, 0.03);

    .socials-content {
      display: flex;
      flex-flow: row nowrap;
      gap: 20px;
      width: 100%;
      max-width: $xl;
      margin: 0 auto;

      .socials-website {
        max-width: 360px;
        width: 100%;
      }
    }

    .link {
      font-size: 12px;
    }
  }

  &-copy {
    background-color: $black-6;
    color: $white-3;
    padding: 21px 15px;

    .copy-content {
      max-width: $xl;
      margin: 0 auto;
    }

    .copy-skey-network-logo {
      width: 65px;
      height: 20px;
    }
  }

  @include mq($md) {
    &-socials {
      .socials-content {
        flex-flow: column wrap;
        gap: 30px;
      }
    }

    &-copy {
      p {
        font-size: 10px;
      }

      .copy-skey-network-logo {
        width: 55px;
        height: 17px;
      }
    }
  }
}
