@use "@scss/global.scss" as *;

.api-keys-table {
  .table-wrapper {
    overflow-x: auto;
  }

  .table {
    margin: 50px 0 40px;
    display: flex;
    flex-flow: column nowrap;
    width: 800px;
    row-gap: 10px;

    &-row {
      display: flex;
      flex-flow: row nowrap;
      column-gap: 10px;
      height: max-content;
    }

    &-row-cell {
      display: flex;
      align-items: center;
      font-weight: $light;
      width: 100%;
      min-height: 40px;
    }

    &-header-cell {
      @extend .table-row-cell;
      font-weight: $light;
      font-size: 13px;
      line-height: 16px;
      color: $black-2;
    }

    .cell-key-nameKey {
      max-width: 159px;
    }
    .cell-key-hash {
      max-width: 290px !important;

      .apiKeys-hash {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 160px;
        width: 100%;
        white-space: nowrap;
      }
    }
    .cell-key-accessPrivileges {
      max-width: 160px;
    }
    .cell-key-termOfValidity {
      max-width: 140px;
    }
    .cell-key-actions {
      max-width: 60px;
    }
  }
}
