@use "@scss/global.scss" as *;

.tooltip {
  $white-shadow: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.05)) drop-shadow(0px 36px 28px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 17px 17px rgba(0, 0, 0, 0.04));

  &-wrapper {
    width: max-content;

    &.full-width {
      width: 100%;
    }
  }

  &-tip {
    border-radius: 10px;
    padding: 12px 15px;
    font-weight: $light;
    font-size: 15px;
    line-height: 1.5;
    z-index: 300;
    word-break: keep-all;
    white-space: pre-wrap;

    &::before {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      border: 10px solid transparent;
      pointer-events: none;
    }
  }

  &-tip-bg-black {
    color: $white-1;
    background: $black-1;
  }

  &-tip-bg-white {
    color: $black-1;
    background: $white-1;
    filter: $white-shadow;
  }

  &-tip-text-center {
    text-align: center;
  }

  &-tip[data-popper-placement^="top"] {
    &::before {
      left: 50%;
      top: 100%;
      margin-left: -10px;
    }

    &.tooltip-tip-bg-black {
      &::before {
        border-top-color: $black-1;
      }
    }
    &.tooltip-tip-bg-white {
      &::before {
        border-top-color: $white-1;
        filter: $white-shadow;
      }
    }
  }

  &-tip[data-popper-placement^="right"] {
    &::before {
      top: 50%;
      right: 100%;
      margin-top: -10px;
    }

    &.tooltip-tip-bg-black {
      &::before {
        border-right-color: $black-1;
      }
    }
    &.tooltip-tip-bg-white {
      &::before {
        border-right-color: $white-1;
        filter: $white-shadow;
      }
    }
  }

  &-tip[data-popper-placement^="bottom"] {
    &::before {
      left: 50%;
      bottom: 100%;
      margin-left: -10px;
    }

    &.tooltip-tip-bg-black {
      &::before {
        border-bottom-color: $black-1;
      }
    }
    &.tooltip-tip-bg-white {
      &::before {
        border-bottom-color: $white-1;
        filter: $white-shadow;
      }
    }
  }

  &-tip[data-popper-placement^="left"] {
    &::before {
      top: 50%;
      left: 100%;
      margin-top: -10px;
    }

    &.tooltip-tip-bg-black {
      &::before {
        border-left-color: $black-1;
      }
    }
    &.tooltip-tip-bg-white {
      &::before {
        border-left-color: $white-1;
        filter: $white-shadow;
      }
    }
  }
}
