@use "@scss/global.scss" as *;

.worker-tokens-table {
  margin: 10px 0 40px;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  row-gap: 10px;

  .table-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    column-gap: 10px;
    height: max-content;
  }

  .row-cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 40px;
    font-weight: $light;
    word-break: break-all;
  }

  .header-cell {
    @extend .row-cell;
    font-weight: $light;
    font-size: 13px;
    line-height: 16px;
    color: $black-2;
  }

  .number-cell {
    max-width: 80px;
  }

  .acitons-cell {
    max-width: 140px;
  }
}
