@use "@scss/global.scss" as *;

.select-icon-button {
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  @include flex-center();
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $black-5;
  }
}
