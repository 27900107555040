@use "@scss/global.scss" as *;

.poap-explore-layout {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  background-color: $white-3;
  width: 100%;
  overflow-x: clip;

  .header-part {
    width: 100%;
    height: max-content;
  }

  .main-part {
    position: relative;
    display: flex;
    width: 100%;
    max-width: $xl;
    margin: 0 auto;
    padding: 0px 15px;
    min-height: 1024px;
    flex-grow: 1;

    .main-content {
      position: relative;
      flex: 1;
      width: 100%;
    }
  }
}
