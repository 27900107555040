@use "@scss/global.scss" as *;

.helpdesk-section {
  padding: 80px 0 120px;

  .title {
    max-width: 245px;
    width: 100%;
  }

  .content {
    width: 100%;
  }

  hr {
    height: 1px;
    background-color: $black-1;
    opacity: 0.3;
  }
}
