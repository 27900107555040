@use "@scss/global.scss" as *;

.image-cropper-utils-btn {
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  height: 42px;
  justify-content: center;

  transition: background-color 0.3s ease-in-out;
  width: 42px;

  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }
}
