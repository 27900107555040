@use "@scss/variables.scss" as *;

* {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
  color: $black-1;
  font-family: "Oxygen", sans-serif;
  background-color: $white-3;
  -webkit-font-smoothing: antialiased;
}

html,
body,
#root,
section {
  height: max-content;
  width: 100%;
}

#root {
  height: 100dvh;
  min-height: 100dvh;

  > * {
    width: 100%;
  }
}

section {
  position: relative;
  overflow-x: hidden;
}

hr {
  margin: 0px;
  padding: 0px;
  width: 100%;
  border: none;
}

a {
  color: unset;
  text-decoration: none;
}

button {
  border: none;
  background-color: transparent;
}

#overlay {
  &-default {
    position: absolute;
    height: 100vh;
    width: 100vw;
    left: 0px;
    top: 0px;
    z-index: 1000;
  }

  &-notification {
    position: fixed;
    height: max-content;
    width: max-content;
    right: 10px;
    bottom: 0px;
    z-index: 1001;

    @media (max-width: $md) {
      top: 0px;
    }
  }

  .alert {
    z-index: 6001;
  }
  .notification {
    z-index: 5001;
  }
  .message {
    z-index: 4001;
  }
  .modal {
    z-index: 3001;
  }
  .dialog {
    z-index: 2001;
  }
  .drawer {
    z-index: 1001;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
