@use "@scss/global.scss" as *;

.poap-explorer-header {
  width: 100%;
  min-height: 84px;
  height: max-content;
  opacity: 0.95;
  background: $white-1;
  box-shadow: $shadow-7;

  &-content {
    display: flex;
    justify-content: space-between;
    max-width: $xl;
    margin: 0 auto;
    padding: 20px 15px;

    .skey-tix-logo-poap {
      width: 195px;
      height: 40px;
    }

    .wallet-address-btn {
      color: $primary;
      cursor: pointer;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: $black-1;
      }
    }
  }

  @include mq($md) {
    &-content {
      padding: 15px 15px;

      .skey-tix-logo-poap {
        width: 122px;
        height: 25px;
      }
    }
  }
}
