@use "@scss/global.scss" as *;

.event-navbar {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: $black-3;
  }
}
