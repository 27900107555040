@use "@scss/global.scss" as *;

.action-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding: 0;
  border-radius: 50%;
  box-sizing: border-box;
  transition: background-color 0.2s ease-in;
  cursor: default;

  &.btn-clickable {
    cursor: pointer;
  }

  &-color-default:hover {
    background-color: rgba($primary, 0.1);
  }

  &-color-danger:hover {
    background-color: rgba($danger-2, 0.1);
  }

  &-color-gray:hover {
    background-color: $gray-3;
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;
    background-color: transparent;
    pointer-events: none;
  }
}
