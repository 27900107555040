@use "@scss/global.scss" as *;

.option {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 15px 10px;
  cursor: pointer;

  &:hover {
    background-color: $black-5;
    border-radius: 4px;
  }

  &-active {
    color: $primary;
    background-color: rgba($primary, 0.1);
  }
}
