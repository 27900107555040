@use "@scss/global.scss" as *;

.share-nft-modal {
  padding: 20px;
  width: 580px;

  &-content {
    position: relative;

    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
