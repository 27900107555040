@use "@scss/global.scss" as *;

.sign-up-for-event-modal {
  --vertical-height: 0;

  display: flex;
  flex-flow: column nowrap;

  width: 820px;

  &-content {
    max-height: 80vh;
    overflow-y: auto;
    padding-right: 10px;
    padding-bottom: 24px;
  }

  &-organization {
    img {
      max-width: 50px;
      max-height: 50px;
    }

    .organization-header {
      font-weight: $light;
      font-size: 10px;
      line-height: 12px;
    }

    .organization-name {
      @include text-ellipsis(2);
      font-weight: $light;
      font-size: 15px;
      line-height: 19px;
    }
  }

  &-inputs {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    @include mq($md) {
      flex-flow: column nowrap;
      row-gap: 20px;
    }
  }

  &-buttons {
    margin-top: 24px;
  }

  .event-image {
    max-width: 120px;
    max-height: 120px;
    border-radius: 10px;
  }

  @include mq($md) {
    justify-content: space-between !important;
    width: 100%;
    height: 100%;

    &-content {
      max-height: calc(100vh - 105px); /* Use vh as a fallback for browsers that do not support Custom Properties */
      max-height: calc(var(--vertical-height, 1vh) * 100 - 75px);
    }

    &-buttons {
      width: 100%;
      margin-top: 0px;
      justify-content: flex-end;

      .button {
        width: 100%;
      }
    }
  }
}
