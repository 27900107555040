@use "@scss/global.scss" as *;

.base-header {
  background: $white-1;
  box-shadow: $shadow-5;

  &-content {
    max-width: $xl;
    padding: 30px 15px;
    margin: auto;
  }
}
