@use "@scss/global.scss" as *;

.create-event-modal {
  width: 1100px;

  &-content {
    max-height: 80vh;
    overflow-y: auto;
    padding-right: 10px;
  }

  &-icon-input {
    margin-top: 2px;
    width: 22px;
  }

  .email-input {
    width: calc(50% - 20px);
  }

  hr {
    height: 1px;
    background-color: $black-1;
    opacity: 0.1;
    width: calc(100% - 40px);
    margin-left: 40px;
  }
}
