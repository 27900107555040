@use "@scss/global.scss" as *;

.checkbox-wrapper {
  .checkbox {
    display: flex;
    column-gap: 10px;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    text-align: justify;

    &-disabled {
      cursor: default;
    }

    &-error {
      .checkbox-input-box:not(
          .checkbox-input:disabled + .checkbox-input-box,
          .checkbox-input:checked + .checkbox-input-box
        ) {
        border: 1px solid $danger-1;
      }
    }

    &-input {
      visibility: hidden;
      display: block;
      height: 0;
      width: 0;
      position: absolute;
      overflow: hidden;
    }

    &-input-box {
      width: 20px;
      height: 20px;
      border: 1px solid $white-5;
      border-radius: 2px;
      background: $white-1;
      flex-shrink: 0;
    }

    &-input:checked + .checkbox-input-box {
      background: url("../../../../public/images/checkbox-mark.svg") center no-repeat;
      background-color: $primary;
      border: none;
      width: 20px;
      height: 20px;
    }

    &-input:disabled:not(.checkbox-input:checked) + .checkbox-input-box {
      background-color: $black-5;
      opacity: 0.5;
    }

    .checkbox-input:disabled:checked + .checkbox-input-box {
      opacity: 0.5;
    }

    .label-text-normal {
      font-weight: $light;
      font-size: 15px;
      line-height: 19px;
    }

    .label-text-small {
      font-weight: $light;
      font-size: 13px;
      line-height: 20px;
    }

    .label-asterisk {
      color: $danger-1;
      font-weight: $bold;
      font-size: 15px;
      margin-left: 5px;
    }
  }

  .checkbox-error-text {
    line-height: 1.2;
    font-size: 14px;
    font-weight: $light;
    color: $danger-1;
    margin-top: 5px;
    margin-left: 32px;
  }
}
