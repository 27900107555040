@use "@scss/global.scss" as *;

.image-viewer {
  &-image-body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    .image-box,
    .image-overlay {
      border-radius: 10px;
    }

    .image-box {
      width: 100%;
      height: auto;
      object-fit: scale-down;
    }

    &:hover .image-overlay {
      opacity: 1;
      cursor: pointer;
    }

    .image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transition: opacity 0.2s ease-in-out;
      background-color: rgba(18, 14, 47, 0.4);
      opacity: 0;
    }

    .overlay-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-preview {
    position: fixed;
    z-index: 1000;
    padding-top: 10px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(34, 34, 51, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      max-width: 90vw;
      height: 100%;
      max-height: 90vh;
      animation: zoom 0.4s;
    }
  }

  &-closeBtn {
    cursor: pointer;
    position: absolute;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    margin: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    &:hover {
      background-color: rgba(34, 34, 51, 0.1);
    }
  }

  @keyframes zoom {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
}
