@use "@scss/global.scss" as *;

.submission-details-modal {
  width: 700px;

  &-content {
    overflow: hidden auto;
    max-height: 80vh;
    padding-right: 10px;
  }

  hr {
    height: 1px;
    background-color: $black-1;
    opacity: 0.3;
    width: 100%;
  }
}
