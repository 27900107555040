@use "@scss/global.scss" as *;

.ticket-settings {
  margin-top: 50px;

  &-title {
    margin-left: 20px;
  }

  &-content {
    margin-top: 32px;
  }

  &-edit {
    padding: 30px 20px 20px;
    background: $white-1;
    border: 1px solid $gray-1;
    border-radius: 10px;
    flex: 1;
  }

  &-logo {
    .logo-image {
      border: 1px solid $gray-1;
      border-radius: 10px;
      width: 160px;
      height: 160px;
      object-fit: scale-down;
    }

    .logo-placeholder {
      width: 160px;
      height: 160px;
    }
  }
}
