@use "@scss/global.scss" as *;

.image-drag-and-drop {
  height: 300px;
  width: 100%;
  text-align: center;
  position: relative;

  .input-file-upload {
    display: none;
  }

  .label-file-upload {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 8px;
    border-style: dashed;
    border-color: $white-5;
    cursor: pointer;
  }

  .drag-active {
    background-color: $white-3;
  }

  .upload-button {
    &:hover {
      background: none;
    }
  }

  .drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}
