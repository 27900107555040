@use "@scss/global.scss" as *;

.nft-preview-modal {
  width: 344px;
  max-width: 344px;
  min-height: 570px;

  &-title {
    width: 100%;
    text-align: center;
  }

  &-image {
    border-radius: 50%;
    width: 100%;
    aspect-ratio: 1;
  }

  .link-hedaer {
    font-size: 12px;
  }
}
