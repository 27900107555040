@use "@scss/variables.scss" as *;
@use "@scss/mixins.scss" as *;

.desktop-xs {
  display: block;

  @include mq($xs) {
    display: none;
  }
}

.mobile-xs {
  display: none;

  @include mq($xs) {
    display: block;
  }
}

.desktop-sm {
  display: block;

  @include mq($sm) {
    display: none;
  }
}

.mobile-sm {
  display: none;

  @include mq(sm) {
    display: block;
  }
}

.desktop-md {
  display: block;

  @include mq($md) {
    display: none;
  }
}

.mobile-md {
  display: none;

  @include mq($md) {
    display: block;
  }
}

.desktop-lg {
  display: block;

  @include mq($lg) {
    display: none;
  }
}

.mobile-lg {
  display: none;

  @include mq($lg) {
    display: block;
  }
}

.desktop-xl {
  display: block;

  @include mq($xl) {
    display: none;
  }
}
.mobile-xl {
  display: none;

  @include mq($xl) {
    display: block;
  }
}

.desktop-xxl {
  display: block;

  @include mq($xxl) {
    display: none;
  }
}

.mobile-xxl {
  display: none;

  @include mq($xxl) {
    display: block;
  }
}
