@use "@scss/global.scss" as *;

.notification {
  position: relative;
  width: 400px;
  min-height: 84px;
  height: max-content;
  padding: 20px 40px 20px 60px;
  margin: 10px 0px;
  background: $white-1;
  border: 1px solid $gray-1;
  border-radius: 10px;
  box-shadow: $shadow-3;
  animation: 0.3s slide-from-right ease-in-out;

  @include mq($md) {
    max-width: 95vw;
    width: 100%;
  }

  &-variant {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  &-close-button {
    position: absolute;
    right: 0px;
    top: 18px;
    padding: 16px 18px;
    width: max-content;
    cursor: pointer;
    background: transparent;

    &:hover {
      svg path {
        fill: $primary;
        opacity: 0.8;
      }
    }
  }

  @keyframes slide-from-right {
    from {
      right: -400px;
      opacity: 0;
    }
    to {
      right: 20px;
      opacity: 1;
    }
  }
}
