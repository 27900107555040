@use "@scss/global.scss" as *;

.seed-display {
  display: flex;
  flex-flow: row wrap;
  gap: 5px 10px;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid $white-5;
  background: $white-1;
  width: max-content;
  cursor: pointer;

  .full-width {
    width: 100%;
  }
}
