@use "@scss/global.scss" as *;

.no-events {
  position: relative;
  overflow-x: clip;
  margin: 40px 0;
  min-height: 590px;

  &-background {
    position: absolute;
    width: 100%;
    height: 590px;
    display: flex;
    justify-content: center;
  }

  &-content {
    position: relative;
    max-width: 530px;
    margin: auto;
    top: 185px;
  }
}
