@use "@scss/global.scss" as *;

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  letter-spacing: 0.01em;
  font-weight: $light;
  line-height: 1.5;
  font-family: "Oxygen", sans-serif;
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
    cursor: default;
    box-shadow: none;
    pointer-events: none;
  }

  &-loading {
    cursor: default;
    opacity: 0.4;

    &:hover {
      box-shadow: none;
    }
  }

  &-primary {
    color: $white-1;
    background: $gradient-1;

    &:hover:not(.button-loading, :disabled) {
      box-shadow: $shadow-4;
    }
  }

  &-secondary {
    color: $primary;
    border: 1px solid $white-4;
    background-color: $white-1;

    &:hover:not(.button-loading, :disabled) {
      color: $black-1;
      box-shadow: $shadow-2;
    }
  }

  &-subtle {
    color: $primary;
    background-color: transparent;

    &:hover:not(.button-loading, :disabled) {
      color: $black-1;
      background-color: $black-5;
    }
  }

  &-red {
    color: $white-1;
    background: $gradient-5;

    &:hover:not(.button-loading, :disabled) {
      color: $white-1;
      background: $gradient-5;
      box-shadow: $shadow-9;
    }
  }

  &-big {
    padding: 12px 26px;
    font-size: 18px;

    .group {
      column-gap: 20px !important;
    }
  }

  &-normal {
    padding: 9px 18px;
    font-size: 15px;

    .group {
      column-gap: 10px !important;
    }
  }
}
