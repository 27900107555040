@use "@scss/global.scss" as *;

.event-page-content {
  margin-top: 50px;

  &-title {
    margin-left: 20px;
  }

  &-image {
    width: 100%;
    max-width: 240px;
    height: auto;
    border-radius: 10px;
  }

  &-content {
    margin-top: 32px;
    padding: 30px 20px 20px;
    background: $white-1;
    border: 1px solid $gray-1;
    border-radius: 10px;
  }

  &-description-wrapper {
    position: relative;
    height: 100px;
    width: 100%;
    overflow: hidden;

    .description-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $gradient-4;
    }
  }
}
