@use "@scss/global.scss" as *;

.navbar {
  width: 100%;
  height: max-content;
  min-height: 60px;
  background: $white-1;
  box-shadow: $shadow-5;

  &-content {
    max-width: $xl;
    padding: 0 15px;
    margin: auto;
  }
}
