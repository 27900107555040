@use "@scss/global.scss" as *;

.social-icon-link {
  display: flex;
  width: max-content;
  height: max-content;
  text-decoration: none;
  cursor: pointer;

  svg path {
    transition: all 0.5s ease;
  }

  &:hover {
    svg path {
      fill: $black-1;
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }
}
