@use "@scss/global.scss" as *;

.search-wallet-page {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  height: 700px;
  padding-top: 90px;
  row-gap: 120px;

  &-header {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    row-gap: 50px;

    .header-title {
      font-size: 48px;
      font-weight: 300;
      letter-spacing: -0.48px;
    }

    .header-image {
      width: 245px;
      height: 109px;
    }
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    row-gap: 30px;
    width: 100%;
    max-width: 340px;
  }

  @include mq($md) {
    padding-top: 140px;
    row-gap: 80px;

    &-header {
      row-gap: 30px;

      .header-title {
        font-size: 30px;
        font-weight: 300;
        letter-spacing: -0.3px;
      }

      .header-image {
        width: 150px;
        height: 66px;
      }
    }
  }
}
