@use "@scss/global.scss" as *;

.upload-image {
  width: 100%;
  max-width: 340px;

  &-input {
    display: none;
  }

  .image-container {
    width: 214px;
    height: 214px;
  }

  .image-placeholder {
    width: 214px;
    height: 214px;
  }

  .image-preview {
    border: 1px solid $gray-1;
    border-radius: 5px;
    width: 214px;
    height: 214px;
    object-fit: scale-down;
  }

  .upload-image-button {
    width: 100%;
  }
}
