@use "@scss/global.scss" as *;

.event-page-settings {
  margin-top: 50px;

  &-title {
    margin-left: 20px;
  }

  &-content {
    margin-top: 32px;
    padding: 30px 20px 20px;
    background: $white-1;
    border: 1px solid $gray-1;
    border-radius: 10px;

    &-col {
      flex: 1;
    }

    .event-agreement {
      margin-bottom: 36px;

      p {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: normal;
        word-break: break-all;
      }
    }
  }
}
