@use "@scss/global.scss" as *;

.recaptcha-disclaimer {
  color: rgba($black-1, 0.6);

  &,
  a {
    font-weight: $light;
    font-size: 13px;
    line-height: 1.5;
  }
}
