@use "@scss/global.scss" as *;

.modal {
  height: max-content;
  width: max-content;
  max-width: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 33px 20px 25px;
  background: $white-1;
  border: 1px solid $gray-1;
  box-shadow: $shadow-3;
  border-radius: 10px;

  @include mq($md) {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 10px;
  }
}
