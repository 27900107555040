@use "@scss/global.scss" as *;

.event-nav-item {
  padding: 17px 20px 17px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  height: 140px;
  transition: opacity 0.2s ease-in;
  border-radius: 10px;
  border: 1px solid transparent;
  border-image: linear-gradient(270deg, rgba(18, 14, 47, 0) 0%, rgba(18, 14, 47, 0.2) 48.44%, rgba(18, 14, 47, 0) 100%)
    1 0 0 0;

  .event-nav-item-content {
    height: 100%;
  }

  &:not(.event-item-selected):hover {
    background: $gray-2;
    box-shadow: $shadow-1;
    border: 1px solid $gray-1;
  }

  &-selected {
    background: $white-1;
    border: 1px solid $gray-1;
    box-shadow: $shadow-1;
  }

  &-status-ended:not(.event-item-selected) {
    .event-item-content {
      opacity: 0.5;
    }
  }

  .event-started-dot {
    background: rgba($orange, 1);
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: pulse-orange 1.75s ease-in-out infinite;

    &:hover {
      animation: pause;
    }
  }

  .event-nav-item-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    word-break: normal;
  }

  @keyframes pulse-orange {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba($orange, 0.7);
    }

    50% {
      transform: scale(1);
      box-shadow: 0 0 0 9px rgba($orange, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba($orange, 0);
    }
  }
}
