@use "@scss/global.scss" as *;

.backdrop-wrapper {
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999;
  animation: appear 0.2s ease-in-out;

  .backdrop {
    height: 100%;
    width: 100%;
    background: rgba(34, 34, 51, 0.2);
  }

  &.open {
    display: block;
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
