@use "@scss/global.scss" as *;

.api-keys-empty-state {
  padding: 80px 0;
  text-align: center;

  .description {
    max-width: 530px;
    width: 100%;
  }
}
