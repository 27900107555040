@use "@scss/global.scss" as *;

.link {
  display: inline-block;
  font-size: 15px;
  font-weight: $light;
  line-height: 1.7;
  letter-spacing: 0.01em;
  color: $primary;
  transition: color, stroke 0.2s ease-in-out;

  &:hover {
    color: $black-1;

    svg path {
      stroke: $black-1;
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }
}
