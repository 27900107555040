@use "@scss/global.scss" as *;

.claim-page {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  max-width: 600px;
  height: 720px;
  margin: 0 auto;
  padding-top: 100px;
  row-gap: 100px;

  &-header {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    row-gap: 32px;

    .header-title {
      font-size: 36px;
      font-weight: 300;
    }
  }

  &-content {
    text-align: center;

    .claim-seed {
      max-width: 340px;
    }
  }

  @include mq($md) {
    height: 576px;
    max-width: 500px;
    padding-top: 70px;
    row-gap: 60px;

    &-header {
      row-gap: 18px;

      .header-title {
        font-size: 30px;
        font-weight: 300;
        line-height: 140%;
        letter-spacing: -0.3px;
      }
    }
  }
}
