@use "@scss/global.scss" as *;

.exlorer-nfts-error {
  display: flex;
  flex-flow: column nowrap;
  gap: 30px;
  align-items: center;
  justify-content: center;
  max-width: 340px;
  text-align: center;
  margin: 0 auto;
  height: 100%;
  max-height: 600px;
}
