@use "@scss/global.scss" as *;

.footer {
  background: $white-7;
  height: 100%;

  &-content {
    max-width: $xl;
    padding: 50px 15px 30px;
    margin: auto;

    .footer-descriptions {
      max-width: 720px;

      .footer-description {
        width: 100%;
        text-align: justify;
      }
    }

    hr {
      height: 2px;
      background-color: rgba($black-1, 0.1);
    }

    .footer-copy {
      color: rgba($black-1, 0.4);
    }
  }

  @include mq($md) {
    .footer-descriptions {
      display: none;
    }
  }
}
